;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"cfd4d811859e3b19533a4d19becef0b7dcba4399"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0,
    enableTracing: true,
    attachStacktrace: true,
    normalizeDepth: 10,

    integrations: [Sentry.browserTracingIntegration()],

    ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'Network request failed',
        /^Timeout$/,
        /^Failed to fetch$/,
        /^Load failed$/,
    ],

    beforeSend(event) {
        if (process.env.NODE_ENV === 'development') {
            return null;
        }
        return event;
    },
});
